<script setup>
import { inject } from "vue";

const close = inject("close");
const canClose = inject("canClose");

if (!close) {
  throw new Error("ModalClose must be used within a Modal component");
}
</script>

<template>
  <header>
    <h1>
      <slot />
    </h1>

    <button v-if="canClose" @click="close" class="close-button-modal">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="#999E9D"
        viewBox="0 0 256 256"
      >
        <path
          d="M208.49,191.51a12,12,0,0,1-17,17L128,145,64.49,208.49a12,12,0,0,1-17-17L111,128,47.51,64.49a12,12,0,0,1,17-17L128,111l63.51-63.52a12,12,0,0,1,17,17L145,128Z"
        ></path>
      </svg>
    </button>
  </header>
</template>

<style scoped lang="scss">
header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;

  padding-bottom: 0;

  width: 100%;

  h1 {
    font-size: 16px;
    font-weight: 700;
  }

  .close-button-modal {
    outline: none;

    height: 40px;
    width: 40px;

    border-radius: 999px;
    border: none;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
  }
}
</style>
